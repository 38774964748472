.root {
    padding-top: 8px;
    padding-bottom: 20px;

}

.loading-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.questionWrapper {
    padding: 8px;
    span {
        display: block;
    }
}

.question_error {
    color: red ;
    display: block;
}

.submit_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}    

.submit_btn {
    margin: 8px 0;
    width: 95%;
}

.input-error {
    border-color: #FF0000;
}

.label-error {
    color: #FF0000;
    border-color: #FF0000;
}

.fieldset {
    display: block !important;
    margin: 8px !important;

    & .MuiFormLabel-root {
        color: rebeccapurple !important;
        font-size: 1.5rem !important;
    }
}

.sectionHead {
    background-color: #cacaca;
    padding: 16px 8px 8px 8px;
    border-radius: 8px 8px 0 0 ;
}